type IconProps = {
  className?: string;
};

export const IconWaterDrop = (props: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      {...props}>
      <path
        fill="currentColor"
        d="M479 848q16 0 24.5-5.5T512 826q0-11-8.5-17t-25.5-6q-42 0-85.5-26.5T337 683q-2-9-9-14.5t-15-5.5q-11 0-17 8.5t-4 17.5q15 84 71 121.5T479 848Zm1 128q-137 0-228.5-94T160 648q0-100 79.5-217.5T480 176q161 137 240.5 254.5T800 648q0 140-91.5 234T480 976Z"
      />
    </svg>
  );
};

export const IconCloudy = (props: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      {...props}>
      <path
        fill="currentColor"
        d="M251 896q-88 0-149.5-61.5T40 685q0-78 50-137t127-71q20-97 94-158.5T482 257q112 0 189 81.5T748 534v24q72-2 122 46.5T920 727q0 69-50 119t-119 50H251Z"
      />
    </svg>
  );
};

export const IconRainy = (props: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      {...props}>
      <path
        fill="currentColor"
        d="M558 973q-11 5-23.5 1T517 959l-69-138q-5-11-1.5-23.5T461 780q11-5 23.5-1t17.5 15l69 138q5 11 1.5 23.5T558 973Zm240-1q-11 5-23.5 1T757 958l-69-138q-5-11-1.5-23.5T701 779q11-5 23.5-1t17.5 15l69 138q5 11 1.5 23.5T798 972Zm-480 0q-11 5-23.5 1.5T277 959l-69-138q-5-11-1-23.5t15-17.5q11-5 23.5-1.5T263 793l69 139q5 11 1 23t-15 17Zm-28-256q-87 0-148.5-61.5T80 506q0-79 56.5-141T277 297q32-56 84.5-88.5T480 176q91 0 152.5 57.5T708 376q79 4 125.5 53.5T880 546q0 70-49.5 120T710 716H290Z"
      />
    </svg>
  );
};

export const IconCloudDot = (props: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      width="64"
      height="64"
      {...props}>
      <defs>
        <pattern
          id="dotPattern"
          width="3"
          height="3"
          patternUnits="userSpaceOnUse">
          <circle cx="2" cy="2" r="1" fill="currentColor" />
        </pattern>
      </defs>
      <g fill="url(#dotPattern)">
        <path
          d="M47.5,32c3.6,0,6.5-2.9,6.5-6.5c0-3.6-2.9-6.5-6.5-6.5c-1.1,0-2.2,0.3-3.1,0.8c-1.8-4.7-6.2-8-11.4-8
        c-5.2,0-9.6,3.3-11.4,8c-0.9-0.5-1.9-0.8-3.1-0.8c-3.6,0-6.5,2.9-6.5,6.5c0,3.6,2.9,6.5,6.5,6.5H47.5z"
        />
      </g>
    </svg>
  );
};
