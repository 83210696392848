import axios from 'axios';
import { filterLocationData } from '../filter-location-data';
import { Location } from '../location';

const baseUrl = 'https://nominatim.openstreetmap.org/search?format=json';
const searchUrl = (query: string) => `${baseUrl}&q=${query}`;

const mapResults = (res: LocationResponse) => {
  console.log('res', res);
  const locations = res.data.map((d) => Location(d));
  return filterLocationData(locations);
};

export const getLocationSearch = (query: string) => {
  const url = searchUrl(query);
  return axios.get(url).then(mapResults);
};

type LocationResponse = {
  data: {
    place_id: number;
    licence: string;
    osm_type: string;
    osm_id: number;
    boundingbox: string[];
    lat: string;
    lon: string;
    display_name: string;
    class: string;
    type: string;
    importance: number;
    icon: string;
  }[];
  status: number;
  statusText: string;
  headers: any;
  config: any;
};
