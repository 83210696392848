import React, { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { CurrentLocation } from '../current-location';
import { LocationResults } from '../location-results';
import { StyledInput, StyledButton, FormItem } from './styles';

type LocationSettingProps = {
  showCurrent?: boolean;
  labelCopy?: string;
};

type FormValues = {
  citySearch: string;
};

export const LocationSetting = ({
  showCurrent,
  labelCopy
}: LocationSettingProps) => {
  const { register, handleSubmit, control } = useForm<FormValues>();
  const [query, setQuery] = useState('');
  const label = labelCopy ? labelCopy : 'Location';

  const onSubmit = (data: FormValues) => {
    setQuery(data.citySearch);
  };

  const values = useWatch({ control });
  useEffect(() => {
    setQuery(values.citySearch || '');
  }, [values, setQuery]);

  return (
    <div>
      <div className="pb-2">
        <b>{label}</b>
      </div>

      {showCurrent && <CurrentLocation />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormItem>
          <StyledInput
            placeholder="Search for a city"
            {...register('citySearch')}
          />
          <StyledButton type="submit">Search</StyledButton>
        </FormItem>
      </form>

      <LocationResults query={query} />
    </div>
  );
};

LocationSetting.defaultProps = {
  showCurrent: true
};
