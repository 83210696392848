import styled from 'styled-components';

type PieContainerProps = {
  height?: string;
};

export const PieContainer = styled.span<PieContainerProps>`
  background: ${(props) => props.theme.colors.sunTime.sky};
  position: relative;
  display: inline-block;
  width: 100%;
  /* height:  */
  flex: 0 0 ${(props) => props.height};

  &::before {
    content: '';
    background: ${(props) => props.theme.colors.sunTime.dark};
    height: 50%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;

export const StyledDial = styled.svg`
  transform: rotate(-140deg);
  background: ${(props) => props.theme.colors.sunTime.dark};
  border-radius: 50%;

  circle {
    fill: ${(props) => props.theme.colors.sunTime.dark};
    stroke: white;
  }
`;
