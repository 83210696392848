import styled from 'styled-components';
import { Input, Button } from '../../../../components';

export const FormItem = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.space[2]}px;
  display: flex;
  align-items: center;
`;

export const StyledInput = styled(Input)`
  display: inline-block;
  height: 100%;
  margin-bottom: 0;
  height: 40px;
  width: auto;
  flex: 1 0 auto;
  border-color: ${({ theme }) => theme.colors.sunTime.skyDark};
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

export const StyledButton = styled(Button)`
  margin: 0;
  height: 40px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  align-self: stretch;
`;
