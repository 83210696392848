import { useEffect, useState } from 'react';

// const OnePMEST = new Date('2020-01-01T18:00:00.000Z');

const getTime = () => new Date();

export const useCurrentTime = (updateTime = 5000) => {
  const [time, setTime] = useState(getTime());

  useEffect(() => {
    const timer = setInterval(() => setTime(getTime()), updateTime);

    return function cleanup() {
      clearInterval(timer);
    };
  });

  return time;
};
