import React from 'react';
import { Outlet } from 'react-router';
import styled from 'styled-components';
import { SingleColumn } from '..';
import { mobileBreakpoint } from '../../constants';

const max = `${mobileBreakpoint}px`;

const Container = styled.div`
  width: 100%;
  max-width: ${max};
  margin: auto;
  text-align: center;
  height: 100%;
`;

export const DefaultLayout = () => (
  <>
    <SingleColumn fullHeight>
      <Container>
        <Outlet />
      </Container>
    </SingleColumn>
  </>
);
