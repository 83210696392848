import { useEffect, useMemo, useState } from 'react';
import SunCalc from 'suncalc';
import { isNilOrEmpty } from '../../../helpers';
import { useLocation } from '../../location';
import { useCurrentTime } from './';

export const useSunTimes = (setDate) => {
  const [times, setTimes] = useState({});
  const time = useCurrentTime(3600000); // 1hour
  const { latitude, longitude } = useLocation();

  const sunDate = useMemo(() => (setDate ? setDate : time), [setDate, time]);

  useEffect(() => {
    if (isNilOrEmpty(latitude) || isNilOrEmpty(longitude)) {
      return;
    }
    var times = SunCalc.getTimes(sunDate, latitude, longitude);
    setTimes(times);
  }, [sunDate, latitude, longitude]);

  return {
    sunrise: new Date(times.sunrise),
    sunset: new Date(times.sunset)
  };
};
