import styled from 'styled-components';

export const Result = styled.div`
  border-radius: ${({ theme }) => theme.radii[2]};
  border-color: ${({ theme }) => theme.colors.neutral.normal};
  text-align: left;
  margin-left: 0;
  padding: 8px 8px;
  margin: 8px 0;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s;

  button {
    color: ${({ theme }) => theme.colors.neutral.darkest};
    background: ${({ theme }) => theme.colors.neutral.lightest};
    border-width: 1px;
    border-color: ${({ theme }) => theme.colors.sunTime.skyDark};
    margin: 0;
  }

  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.neutral.lightest};
    button {
      color: ${({ theme }) => theme.colors.neutral.dark};
      background: ${({ theme }) => theme.colors.sunTime.skyDark};
      color: ${({ theme }) => theme.colors.neutral.lightest};
      border-color: ${({ theme }) => theme.colors.neutral.dark};
    }
  }
`;
