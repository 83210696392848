import { mobileBreakpoint } from '../constants';

const colors = {
  background: '#fafafa',
  surface: '#FBF8FF',
  onSurface: '#1D1A27',
  sunTime: {
    dark: '#192E44',
    sky: '#4673EC',
    skyDark: '#1d49c1',
    sun: '#FFE70B'
  },
  neutral: {
    white: '#fff',
    lightest: '#F4F4F4',
    light: '#D9D9D9',
    normal: '#BCAFAF',
    dark: '#7E7E7E',
    darkest: '#070E17'
  }
};
const breakpoints = [`${mobileBreakpoint}px`, '700px', '800px', '1000px'];
const space = [0, 4, 8, 12, 16, 24, 32, 40];
const fontSizes = [12, 14, 16, 20, 24, 32, 48, 64];
const fonts = {
  body: 'Open Sans, sans-serif, system-ui',
  heading: 'Open Sans, sans-serif, system-ui',
  secondary: 'Averia Sans Libre, cursive',
  monospace: 'Menlo, monospace'
};
const radii = [0, '4px', '8px', '12px'];
const opacity = [0, '20%', '40%', '60%', '80%', '100%'];
const shadows = {
  small: '0 0 4px rgba(0, 0, 0, .125)',
  large: '0 0 24px rgba(0, 0, 0, .125)'
};

export const theme = {
  colors,
  breakpoints,
  space,
  fontSizes,
  fonts,
  radii,
  opacity,
  shadows
} as const;

declare module 'styled-components' {
  export interface DefaultTheme {
    colors: typeof colors;
    breakpoints: typeof breakpoints;
    space: typeof space;
    fontSizes: typeof fontSizes;
    fonts: typeof fonts;
    radii: typeof radii;
    opacity: typeof opacity;
    shadows: typeof shadows;
  }
}
