import mixpanel from 'mixpanel-browser';

// eslint-disable-next-line no-undef
const enabled = process.env.NODE_ENV === 'production';

export const init = () => {
  if (enabled) {
    mixpanel.init('bee7b85e97ba506e05eef53e4b66b171', { debug: enabled });
  }
};

export const page = () => null;

export const track = (...args) => enabled && mixpanel.track(...args);
