import styled from 'styled-components';

export const StyledCurrentTime = styled.span`
  display: block;
  color: ${(props) => props.theme.colors.neutral.white};

  .time {
    font-size: ${(props) => props.theme.fontSizes[6]}px;
    text-align: center;
  }
`;
