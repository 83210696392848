import { useEffect, useRef } from 'react';
import { degToRad } from '../../../../helpers';
import { IconCloudy, IconWaterDrop, useWeatherHourly } from '../../../weather';
import { useSunTimes } from '../../hooks';
import { WeatherArcsStyles } from './styles';

type WeatherArcsProps = {
  size: number;
  sunProgress: number;
};

/**
 * Ok so I want to create an arc for cloud coverage between a certain hour
 * The inputs are the sunrise time and the sunset time and the start and end angles of the slice
 * Well also need to map the hours returned from the weather data to angles within the slice
 *
 * Scene:
 * - 6:48am sunrise (223 deg)
 * - 7:30pm sunset (327 deg)
 *
 * Sun:
 * - 4:07pm (297 deg)
 *
 * Clouds:
 * - 6:48am (223 deg) - 7:30am (227 deg)
 * - 11:30am (259 deg) - 12:30pm (264 deg)
 * - 4:30pm (288 deg) - 5:30pm (293 deg)
 *
 * The core piece of this is the rendering detail level, when do the clouds change?
 * - every hour
 *
 * So different stroke widths for different cloud coverages, per hour
 * - 0%: 0
 * - 25%: 1
 * - 50%: 2
 * - 75%: 3
 * - 100%: 4
 *
 *
 */

export const WeatherArcs = ({ size, sunProgress }: WeatherArcsProps) => {
  const { sunrise, sunset } = useSunTimes();
  const { data, isLoading: loading } = useWeatherHourly();
  // don't render if stuff isn't ready yet
  console.log({ size });
  if (isNaN(sunProgress)) return null;

  // const data = [50, 50];
  // const angleRange: [number, number] = [Math.PI / 4, (3 * Math.PI) / 4];
  const angleRange: [number, number] = [degToRad(220), degToRad(327)];

  console.log(angleRange);

  const cloudCoveragesFromData = data?.hourly.map(({ clouds, pop, dt }) => {
    return {
      time: new Date(dt * 1000),
      coverage: clouds,
      pop: pop
    };
  });

  if (loading) return null;
  if (!cloudCoveragesFromData) return null;

  const cloudCoveragesWithinDay = cloudCoveragesFromData.filter(
    ({ time }) => time > sunrise && time < sunset
  );

  const hasClouds = cloudCoveragesWithinDay.some(
    ({ coverage }) => coverage > 0
  );

  const hasRain = cloudCoveragesWithinDay.some(({ pop }) => pop > 0);

  return (
    <WeatherArcsStyles>
      <CloudCoverageChart
        sunrise={sunrise}
        sunset={sunset}
        startAngle={degToRad(223)}
        endAngle={degToRad(327.3)}
        radius={size / 3.8}
        width={size}
        height={size}
        cloudCoverages={cloudCoveragesWithinDay}
      />
      {hasClouds && <IconCloudy className="cloudIcon" />}
      {hasRain && <IconWaterDrop className="rainIcon" />}
    </WeatherArcsStyles>
  );
};

const createDotPattern = (
  ctx: CanvasRenderingContext2D,
  size: number,
  color: string
) => {
  const patternCanvas = document.createElement('canvas');
  patternCanvas.width = size;
  patternCanvas.height = size;
  const patternCtx = patternCanvas.getContext('2d');

  if (patternCtx) {
    patternCtx.fillStyle = color;
    patternCtx.beginPath();
    patternCtx.arc(size / 2, size / 2, size / 4, 0, 2 * Math.PI);
    patternCtx.fill();
  }

  return ctx.createPattern(patternCanvas, 'repeat');
};

type CloudCoverage = {
  time: Date;
  coverage: number;
  pop: number;
};

type CloudCoverageChartProps = {
  sunrise: Date;
  sunset: Date;
  startAngle: number;
  endAngle: number;
  radius: number;
  width: number;
  height: number;
  cloudCoverages: CloudCoverage[];
};

const CloudCoverageChart: React.FC<CloudCoverageChartProps> = ({
  sunrise,
  sunset,
  startAngle,
  endAngle,
  radius,
  width,
  height,
  cloudCoverages
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const timeToAngle = (time: Date) => {
    const dayDuration = sunset.getTime() - sunrise.getTime();
    const timeProgress = (time.getTime() - sunrise.getTime()) / dayDuration;
    return startAngle + timeProgress * (endAngle - startAngle);
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    ctx.clearRect(0, 0, width, height);

    const centerX = width / 2;
    const centerY = height / 2;
    const dotPattern = createDotPattern(ctx, 3, 'rgba(0, 0, 0, 0.7)');
    const dotPatternPop = createDotPattern(ctx, 4, 'rgba(29, 39, 241, 0.917)');

    for (let i = 0; i < cloudCoverages.length; i++) {
      const { time, coverage } = cloudCoverages[i];
      if (time >= sunrise && time <= sunset) {
        const angle = timeToAngle(time);
        const nextHour = new Date(time.getTime() + 60 * 60 * 1000);
        const nextAngle = timeToAngle(nextHour) + 0.002;

        /**
         * CLOUDS
         */
        if (coverage > 0) {
          const coverageRatio = Math.max(250, 250 * (300 / width));
          ctx.beginPath();
          ctx.arc(centerX, centerY, radius, angle, nextAngle);
          ctx.lineWidth = 1;
          ctx.strokeStyle = 'rgba(80, 80, 80, 0.3)';
          ctx.stroke();
          const coverageHeight = (coverage / coverageRatio) * radius;
          const outerRadius = radius + coverageHeight;
          ctx.beginPath();
          ctx.arc(centerX, centerY, radius, angle, nextAngle);
          ctx.arc(centerX, centerY, outerRadius, nextAngle, angle, true);
          ctx.closePath();
          // ctx.fillStyle = 'rgba(0, 0, 0, 0.8)';
          ctx.fillStyle = dotPattern as NonNullable<typeof dotPattern>;
          ctx.fill();
        }

        /**
         * POP
         */
        const pop = cloudCoverages[i].pop;
        // const pop = 1;
        const popRatio = Math.max(3, 1 * (1 / width));
        const popHeight = (pop / popRatio) * radius;
        const popInnerRadius = width / 7;
        const popOuterRadius = popInnerRadius + popHeight;
        ctx.beginPath();
        ctx.arc(centerX, centerY, popInnerRadius, angle, nextAngle);
        ctx.arc(centerX, centerY, popOuterRadius, nextAngle, angle, true);
        ctx.closePath();
        ctx.fillStyle = dotPatternPop as NonNullable<typeof dotPattern>;
        ctx.fill();
      }
    }
  }, [
    sunrise,
    sunset,
    startAngle,
    endAngle,
    radius,
    width,
    height,
    cloudCoverages
  ]);

  return <canvas ref={canvasRef} width={width} height={height} />;
};
