import React from 'react';
import { useLocation } from '../../hooks';
import { LocationBase } from '../location-result/LocationBase';

export const CurrentLocation = () => {
  const location = useLocation();

  if (!location) return null;

  return (
    <div className="pb-2 w-full">
      Currently
      <span className="ml-2 bg-slate-200">
        <LocationBase location={location} />
      </span>
    </div>
  );
};
