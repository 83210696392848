/* eslint-disable react/display-name */
import { ComponentProps, forwardRef } from 'react';
import classNames from 'classnames';

export type InputProps = Omit<ComponentProps<'input'>, 'size'>;

export const Input = forwardRef(({ ...rest }: InputProps, ref: any) => {
  return (
    <input
      {...rest}
      ref={ref}
      className={classNames(
        'mb-1 py-2 px-2 text-md border-1 border-solid rounded w-[50%]',
        'w-full block border rounded-sm',
        'focus:outline-none focus:border-primary-500 focus:ring-primary-500 focus:ring-1',
        rest.className
      )}
    />
  );
});
