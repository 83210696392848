import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useLocation } from '../../../location';

const Container = styled.div`
  margin: auto;
  font-size: ${({ theme }) => theme.fontSizes[3]}px;
  margin-top: 16px;
  margin-bottom: 10%;
  color: #fff;
`;

const ChangeLink = styled(Link)`
  color: #aaa;
`;

export const SundialLocation = () => {
  const { city } = useLocation();

  return (
    <Container>
      {city} - <ChangeLink to={'/settings'}>Settings</ChangeLink>
    </Container>
  );
};
