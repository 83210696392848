import React from 'react';
import { useLogPage } from '../../../helpers';
import { InstallBanner } from '../../pwa';
import { Sundial } from '../components';
import { useSunTimes } from '../hooks/use-sun-times';

export const TimeView = () => {
  useLogPage('Home');
  const { sunrise, sunset } = useSunTimes();

  return (
    <>
      <InstallBanner />
      <Sundial {...{ sunrise, sunset }} />
    </>
  );
};
