import React, { useMemo } from 'react';
import { displayTime } from '../../helpers/display-time';
import { useCurrentTime, useSunTimes } from '../../hooks';
import { Container, Title, Time } from './styles';

const getNextMidnight = () => new Date(new Date().setHours(24, 0, 0, 0));
const getNoon = () => new Date(new Date().setHours(12, 0, 0, 0));

const getNextSunriseDay = (time: Date) => {
  const nextMidnight = getNextMidnight();
  const noon = getNoon();

  // if it's evening - get sunrise time for tomorrow
  if (time > noon && time < nextMidnight) {
    return { time: getNextMidnight(), message: `Tomorrow's Sunrise` };
  }

  return { time: time, message: `Today's Sunrise` };
};

export const SetMessage = () => {
  const currentTime = useCurrentTime();
  const { time, message } = useMemo(
    () => getNextSunriseDay(currentTime),
    [currentTime]
  );
  const { sunrise } = useSunTimes(time);

  return (
    <Container>
      <Title>{message}</Title>
      <Time>{displayTime(sunrise)}</Time>
    </Container>
  );
};
