import styled from 'styled-components';

export const StyledSun = styled.svg`
  position: absolute;
  top: 0;
  z-index: 3;
  overflow: visible;

  circle#sun {
    fill: yellow;
    stroke: #777;
  }
`;
