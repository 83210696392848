import { useContext } from 'react';
import { LocationContext } from '../state/location-provider';

// Toronto city hall coords
const defaultLocation = {
  latitude: '43.653225',
  longitude: '-79.383186',
  city: 'Toronto',
  state: 'Ontario',
  country: 'Canada'
};

export const useLocation = () => {
  const { location } = useContext(LocationContext);
  return location || defaultLocation;
};
