import React from 'react';
import { Button } from '../../../../components';

type LocationActionsProps = {
  show: boolean;
};

export const LocationActions = ({ show }: LocationActionsProps) => {
  if (!show) return null;

  return (
    <span>
      <Button size="sm">Select</Button>
    </span>
  );
};
