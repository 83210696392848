import React from 'react';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from 'recharts';
import { WeatherHourly } from '../../hooks/use-weather-hourly';

type HourlyKeys = keyof WeatherHourly;
export type TimeFrame = 'remainingDay' | 'next24Hours' | 'all';

interface HourlyChartProps {
  data: WeatherHourly[];
  property: Exclude<HourlyKeys, 'weather' | 'rain'>;
  label: string;
  timeFrame?: TimeFrame;
}

const formatter = new Intl.DateTimeFormat('en-us', {
  hour: 'numeric'
});

const filterDataByTimeFrame = (
  data: WeatherHourly[],
  timeFrame: TimeFrame
): WeatherHourly[] => {
  const now = new Date();

  switch (timeFrame) {
    case 'remainingDay':
      return data.filter((hour) => {
        const hourDate = new Date(hour.dt * 1000);
        return hourDate.getDate() === now.getDate();
      });
    case 'next24Hours':
      // eslint-disable-next-line no-case-declarations
      const twentyFourHoursLater = new Date(
        now.getTime() + 24 * 60 * 60 * 1000
      );
      return data.filter((hour) => {
        const hourDate = new Date(hour.dt * 1000);
        return hourDate.getTime() <= twentyFourHoursLater.getTime();
      });
    case 'all':
    default:
      return data; // No filtering needed
  }
};

export const HourlyChart: React.FC<HourlyChartProps> = ({
  data,
  property,
  label,
  timeFrame = 'all'
}) => {
  const filteredData = filterDataByTimeFrame(data, timeFrame);

  const chartData = filteredData.map((hour) => ({
    time: new Date(hour.dt * 1000),
    formattedTime: formatter.format(new Date(hour.dt * 1000)),
    value: hour[property]
  }));

  return (
    <div style={{ width: '100%', height: 100 }}>
      <ResponsiveContainer>
        <LineChart
          data={chartData}
          syncId={'weather-hourly'}
          margin={{ top: 5, right: 30, left: 0, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="formattedTime" />
          <YAxis domain={['dataMin', 'dataMax']} />
          <Tooltip />
          <Legend verticalAlign="top" height={36} />
          <Line
            type="linear"
            dataKey="value"
            name={label}
            stroke="#8884d8"
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
