import React, { useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { httpBatchLink } from '@trpc/client';
import { render } from 'react-dom';

import { BrowserRouter } from 'react-router-dom';
import smartOutline from 'smart-outline';
import { ThemeProvider } from 'styled-components';

import { LocationProvider } from './features/location';
import { logger, trpc } from './helpers';
import Routes from './routes';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import GlobalStyle from './styles/global';
import { theme } from './styles/theme';
import './index.css';

smartOutline.init();
logger.init();

window.addEventListener(
  'focus',
  function () {
    logger.track('Window Focus');
  },
  false
);

const Root = () => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: true,
            staleTime: 5 * (60 * 1000), // 5 mins
            cacheTime: 10 * (60 * 1000) // 10 mins
          }
        }
      })
  );

  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpBatchLink({
          url: `${process.env.REACT_APP_WORKER_BASE_URL}/trpc`
        })
      ]
    })
  );

  return (
    <React.StrictMode>
      <BrowserRouter>
        <trpc.Provider client={trpcClient} queryClient={queryClient}>
          <QueryClientProvider client={queryClient}>
            <LocationProvider>
              <ThemeProvider theme={theme}>
                <GlobalStyle />
                <Routes />
              </ThemeProvider>
            </LocationProvider>
          </QueryClientProvider>
        </trpc.Provider>
      </BrowserRouter>
    </React.StrictMode>
  );
};

render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
