import React from 'react';
import { Routes as ReactRoutes, Route } from 'react-router-dom';
import { DefaultLayout } from './components';
import { WelcomeView } from './features/information';
import { SettingsView } from './features/settings';
import { TimeView } from './features/suntime';
import { WeatherView } from './features/weather';

const Routes = () => (
  <ReactRoutes>
    <Route path="/" element={<DefaultLayout />}>
      <Route index element={<TimeView />} />
      <Route path="/settings" element={<SettingsView />} />
      <Route path="/welcome" element={<WelcomeView />} />
      <Route path="/weather" element={<WeatherView />} />
    </Route>
  </ReactRoutes>
);

export default Routes;
