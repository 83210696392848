import styled from 'styled-components';

export const WeatherArcsStyles = styled.span`
  position: absolute;
  z-index: 1;
  left: 0;

  .cloudIcon,
  .rainIcon {
    position: absolute;
    z-index: 1;
    right: 76%;
    top: 28%;
    aspect-ratio: 1;
    height: 6%;
    width: 6%;
    color: #cecece;
  }

  .rainIcon {
    right: 68%;
    top: 36%;
    color: #9f9fe3;
  }
`;
