import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '../../../../components';
import { useLogPage } from '../../../../helpers';
import { LocationSetting } from '../../../location';
import { Container, Header, Heading } from './styles';

export const SettingsView = () => {
  const navigate = useNavigate();
  useLogPage('Settings');

  return (
    <Container>
      <Header>
        <Button
          className="bg-slate-300 hover:bg-slate-300 hover:text-primary-950"
          onClick={() => navigate('/')}>
          Cancel
        </Button>
        <Heading>Settings</Heading>
      </Header>
      <LocationSetting />

      <Link to={'/weather'} className="fixed bottom-0 text-slate-100">
        Weather
      </Link>
    </Container>
  );
};
