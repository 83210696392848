import { createContext, useEffect, useMemo, useCallback } from 'react';
import {
  useLocation as useRouterLocation,
  useNavigate
} from 'react-router-dom';
import { useLocalStorage } from '../../../../helpers';
import { Location } from '../../types';

type LocationContextType = {
  location: Location | null;
  setLocation: (location: Location) => void;
};

export const LocationContext = createContext<LocationContextType>(
  {} as unknown as LocationContextType
);

type LocationProviderProps = {
  children: React.ReactNode;
};

export const LocationProvider = ({ children }: LocationProviderProps) => {
  const routerLocation = useRouterLocation();
  const navigate = useNavigate();
  const [location, setLocationBase] = useLocalStorage('location', null);

  const setLocation = useCallback(
    (newLocation: Location) => {
      setLocationBase(newLocation);
    },
    [setLocationBase]
  );

  const contextValue = useMemo(
    () => ({
      location,
      setLocation
    }),
    [location, setLocation]
  );

  useEffect(() => {
    if (location === null && routerLocation.pathname === '/') {
      navigate('/welcome');
    }
  }, [location, navigate, routerLocation]);

  return (
    <LocationContext.Provider value={contextValue}>
      {children}
    </LocationContext.Provider>
  );
};
