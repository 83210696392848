import { reject, isNil } from 'ramda';
import * as devConsole from './dev-console';
import * as mixpanel from './mixpanel';

const init = () => {
  mixpanel.init();
};

const page = (name, properties) => {
  const args = reject(isNil, [name, properties]);
  mixpanel.page(...args);
  devConsole.log('page', ...args);
};

const track = (name, properties) => {
  mixpanel.track(name, properties);
  devConsole.log('track', name, properties);
};

const logger = {
  init,
  page,
  track
};

export default logger;
