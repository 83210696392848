import { useState } from 'react';
import { useWeatherHourly } from '../../hooks/use-weather-hourly';
import { HourlyChart, TimeFrame } from '../hourly-chart';

export const WeatherHourly = () => {
  const { data, isLoading, error } = useWeatherHourly();
  const [timeFrame, setTimeFrame] = useState<TimeFrame>('all');

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <div>
      <div className="my-2 flex justify-center gap-4">
        <button
          onClick={() => setTimeFrame('remainingDay')}
          className={`px-2 py-1 rounded-md ${
            timeFrame === 'remainingDay'
              ? 'bg-blue-600 text-white'
              : 'bg-blue-100 text-blue-600'
          }`}>
          Remaining Day
        </button>
        <button
          onClick={() => setTimeFrame('next24Hours')}
          className={`px-2 py-1 rounded-md ${
            timeFrame === 'next24Hours'
              ? 'bg-blue-600 text-white'
              : 'bg-blue-100 text-blue-600'
          }`}>
          Next 24 Hours
        </button>
        <button
          onClick={() => setTimeFrame('all')}
          className={`px-2 py-1 rounded-md ${
            timeFrame === 'all'
              ? 'bg-blue-600 text-white'
              : 'bg-blue-100 text-blue-600'
          }`}>
          All Data
        </button>
      </div>

      <HourlyChart
        data={data.hourly}
        timeFrame={timeFrame}
        property="temp"
        label="Temperature"
      />
      <HourlyChart
        data={data.hourly}
        timeFrame={timeFrame}
        property="uvi"
        label="UV Index"
      />
      <HourlyChart
        data={data.hourly}
        timeFrame={timeFrame}
        property="pop"
        label="Pop"
      />
      <HourlyChart
        data={data.hourly}
        timeFrame={timeFrame}
        property="clouds"
        label="Clouds"
      />
      <HourlyChart
        data={data.hourly}
        timeFrame={timeFrame}
        property="wind_speed"
        label="Wind Speed"
      />
      <HourlyChart
        data={data.hourly}
        timeFrame={timeFrame}
        property="humidity"
        label="Humidity"
      />
      <HourlyChart
        data={data.hourly}
        timeFrame={timeFrame}
        property="pressure"
        label="Pressure"
      />
      <HourlyChart
        data={data.hourly}
        timeFrame={timeFrame}
        property="visibility"
        label="Visibility"
      />
    </div>
  );
};
