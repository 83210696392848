import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useWeather } from '../../hooks/use-weather';
import { StyledWeatherSummary } from './styles';

type TempProps = {
  value: number | string;
  rounded?: boolean;
  label?: string;
};

const WeatherValue = ({ value, rounded = true, label }: TempProps) => {
  const roundedValue =
    rounded && typeof value === 'number' ? Math.round(value) : value;
  return (
    <div>
      {label && <div className="label">{label}</div>}
      <span className="value">{roundedValue}</span>
    </div>
  );
};

export const WeatherBox = () => {
  const { data, isLoading, error, refetch } = useWeather();
  const navigate = useNavigate();

  const refreshTemperature = async () => {
    await refetch();
  };

  const onClickWeatherBox = () => {
    refetch();
    navigate('/weather');
  };

  if (isLoading) {
    return <div>Loading Weather Data...</div>;
  }

  if (error) {
    return (
      <div>
        Error: {error.message}
        <button onClick={refreshTemperature}>Retry</button>
      </div>
    );
  }
  if (!data) {
    return null;
  }

  return (
    <StyledWeatherSummary onClick={onClickWeatherBox}>
      {!isLoading && data?.main?.temp && (
        <>
          <WeatherValue label="Temperature" value={data.main.temp} />
          <WeatherValue label="Feels Like" value={data.main.feels_like} />
          <WeatherValue label="Clouds" value={`${data.clouds.all}%`} />
          <div>
            <img
              className="weather-icon"
              src={`http://openweathermap.org/img/w/${data.weather[0].icon}.png`}
            />
          </div>
        </>
      )}
    </StyledWeatherSummary>
  );
};
