import { map, toLower } from 'ramda';

const createUniqueKey = (names: string[]) => {
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [city, area, state, country] = map(toLower, names);
  return `${city}-${state}-${country}`;
};

const parseName = (name: string) => {
  const names = name.split(', ');
  const [city, area, state, country] = names;
  return {
    city,
    area,
    state,
    country,
    uniqueKey: createUniqueKey(names)
  };
};

type LocationResponseSlice = {
  place_id: number;
  lat: string;
  lon: string;
  display_name: string;
  importance: number;
};

export const Location = (locationResponse: LocationResponseSlice) => {
  // console.log('location', data);
  return {
    id: locationResponse.place_id,
    latitude: locationResponse.lat,
    longitude: locationResponse.lon,
    importance: locationResponse.importance,
    ...parseName(locationResponse.display_name)
  };
};
