import React from 'react';
import { useLogPage } from '../../../../helpers';
import { LocationSetting } from '../../../location';
import { Sundial, useSunTimes } from '../../../suntime';
import { Container, InfoContainer } from './styles';

export const WelcomeView = () => {
  useLogPage('Welcome');
  const { sunrise, sunset } = useSunTimes();

  return (
    <>
      <Container>
        <InfoContainer>
          <h1>Welcome!</h1>

          <p>SunTime is a simple sun tracker. Use it to:</p>
          <ul className="pb-2">
            <li>
              <span>😎</span>
              <div>
                <b>Get outside</b> - take a break or plan a walk
              </div>
            </li>
            <li>
              <span>☀️</span>
              <div>
                <b>Improve your sleep</b> - morning sun exposure can be great
              </div>
            </li>
            <li>
              <span>🌇</span>
              <div>
                <b>Plan Ahead</b> don{`'`}t miss another sunset
              </div>
            </li>
          </ul>

          <LocationSetting
            showCurrent={false}
            labelCopy="Get started by setting your location:"
          />
          <div className="mb-8" />
        </InfoContainer>
        <Sundial {...{ sunrise, sunset }} />
      </Container>
    </>
  );
};
