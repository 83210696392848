import React from 'react';
import { arcLength, circumference } from '../../../../helpers';
import { SetMessage } from '../set-message';
import { Sun } from '../sun';
import { WeatherArcs } from '../weather-arcs';
import { PieContainer, StyledDial } from './styles';

type SetViewProps = {
  size: number;
};

const SetView = ({ size }: SetViewProps) => {
  return (
    <PieContainer>
      <StyledDial width={size} height={size}></StyledDial>
      <SetMessage />
    </PieContainer>
  );
};

type PieDisplayProps = {
  size: number;
  sunProgress: number;
};

export const PieDisplay = ({ size, sunProgress }: PieDisplayProps) => {
  const center = size / 2;
  const radius = center / 2;
  const dashSize = `${arcLength(radius, 0.3)} ${circumference(radius)}`;
  const isSet = sunProgress === 0 || sunProgress === 1;

  if (isSet) return <SetView size={size} />;
  if (!size) return null;

  return (
    <PieContainer height={`${size}px`}>
      <Sun size={size} sunProgress={sunProgress} />
      <WeatherArcs size={size} sunProgress={sunProgress} />
      <StyledDial width={size} height={size}>
        <circle
          r={radius}
          cx={center}
          cy={center}
          strokeWidth={center}
          strokeDasharray={dashSize}
        />
      </StyledDial>
    </PieContainer>
  );
};
