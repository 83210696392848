import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.sunTime.dark};

  /* Border for desktop view, not mobile */
  border-radius: 0;
  @media (min-width: ${(props) => props.theme.breakpoints[0]}) {
    border-radius: 8px;
  }
`;

export const UpperContainer = styled.div`
  background: ${(props) => props.theme.colors.sunTime.sky};
  z-index: 1;
  min-height: 200px;
  flex: 0.5 0 25%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LowerContainer = styled.div`
  position: absolute;
  z-index: 4;
  width: 100%;
  height: 25%;
  bottom: 0px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
