import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { logger } from '../../../../helpers';
import { LocationContext } from '../../state';
import { Location } from '../../types';
import { LocationActions } from './LocationActions';
import { LocationBase } from './LocationBase';
import { Result } from './styles';

type LocationResultProps = {
  location: Location;
  showActions?: boolean;
};

export const LocationResult = ({
  location,
  showActions = true
}: LocationResultProps) => {
  const { setLocation } = useContext(LocationContext);
  const navigate = useNavigate();

  const onSelect = () => {
    setLocation(location);
    logger.track('Set Location', { location });
    navigate('/');
  };

  return (
    <Result onClick={onSelect}>
      <LocationBase location={location} />
      <LocationActions show={showActions} />
    </Result>
  );
};
