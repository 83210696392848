import { __, prop, propSatisfies, filter, gt, uniqBy } from 'ramda';
import { Location } from '../../types';

const byImportance = (num: number) =>
  filter(propSatisfies(gt(__, num), 'importance'));

export const filterLocationData = (locations: Location[]) => {
  const important = byImportance(0.45)(locations);
  // if there are no results, lower the importance filter a bit
  const results = important.length ? important : byImportance(0.4)(locations);
  return uniqBy(prop('uniqueKey'))(results);
};
