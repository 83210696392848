import styled from 'styled-components';

export const Container = styled.div`
  color: white;
  height: 75%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  justify-content: center;
`;

export const Title = styled.div`
  font-size: ${(props) => props.theme.fontSizes[1]}px;
  color: ${(props) => props.theme.colors.neutral.normal};
`;

export const Time = styled.div`
  font-size: ${(props) => props.theme.fontSizes[3]}px;
`;
