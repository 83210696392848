type Value = number | Date;

const percentageLimits = (value: Value) => {
  return Math.min(Math.max(0, +value), 1);
};

export const getPercentage = (min: Value, value: Value, max: Value) => {
  const spread = +max - +min;
  const percentage = (+value - +min) / spread;
  return percentageLimits(percentage);
};
