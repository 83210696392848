import React from 'react';
import { Link } from 'react-router-dom';
import { useLogPage } from '../../../helpers';
import { WeatherHourly } from '../components/weather-hourly';

export const WeatherView = () => {
  useLogPage('Weather');

  return (
    <>
      <div className="relative">
        <Link to="/" className="absolute left-0 mt-1 text-blue-600 underline">
          Home
        </Link>
        <h1 className="text-xl font-bold text-center flex-1">Hourly Weather</h1>
      </div>
      <WeatherHourly />
    </>
  );
};
