import React, { useMemo } from 'react';
import { mobileBreakpoint } from '../../../../constants';
import { useWindowSize } from '../../../../helpers';
import { getPercentage } from '../../helpers/get-percentage';
import { useCurrentTime } from '../../hooks';
import { SundialLocation } from '../location-display';
import { PieDisplay } from '../pie-display';
import { SunTimes } from '../sun-times';
import { UpperBox } from '../upper-box';
import { Container, LowerContainer, UpperContainer } from './styles';

type Props = {
  sunrise: Date;
  sunset: Date;
};

export const Sundial = ({ sunrise, sunset }: Props) => {
  const time = useCurrentTime(10000);
  const { width } = useWindowSize();

  const sunProgress = useMemo(
    () => getPercentage(sunrise, time, sunset),
    [time, sunrise, sunset]
  );

  const size = useMemo(() => {
    if (width && width >= mobileBreakpoint) {
      return mobileBreakpoint;
    }
    return width;
  }, [width]);

  console.log({ size });

  return (
    <Container>
      <LowerContainer>
        <SunTimes {...{ sunrise, sunset }} />
        <SundialLocation />
      </LowerContainer>

      <UpperContainer>
        <UpperBox />
      </UpperContainer>

      <PieDisplay size={size as number} sunProgress={sunProgress} />
    </Container>
  );
};
