import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  html, body, #root { height: 100%; }

  body {
    margin: 0;
    background-color: ${(props) => props.theme.colors.background};
    font-family: ${(props) => props.theme.fonts.body};
    * {
      box-sizing: border-box;
    }
  }

  :root {
    --font-size-1: 12px;
    --font-size-2: 14px;
    --font-size-3: 16px;
    --font-size-4: 20px;
    --font-size-5: 24px;
    --font-size-6: 32px;
    --font-size-7: 48px;
    --font-size-8: 64px;
  }
`;
