import React from 'react';
import { WeatherBox } from '../../../weather';
import { displayTime } from '../../helpers/display-time';
import { useCurrentTime } from '../../hooks';
import { StyledCurrentTime } from './styles';

export function UpperBox() {
  const time = useCurrentTime();

  return (
    <StyledCurrentTime>
      <div className="time">{displayTime(time)}</div>
      <WeatherBox />
    </StyledCurrentTime>
  );
}
