import React from 'react';

type Props = {
  location: {
    city: string;
    state: string;
    country: string;
  };
};

export const LocationBase = ({ location }: Props) => {
  const { city, country, state } = location;

  return (
    <span>
      <span>{city}</span>, <span>{state}</span>, <span>{country}</span>
    </span>
  );
};
