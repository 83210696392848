import { trpc } from '../../../helpers';
import { useLocation } from '../../location';

const minutes = 5;
const time = 1000 * 60 * minutes;

export const useWeather = () => {
  const { latitude, longitude } = useLocation();
  return trpc.weatherLL.useQuery(
    { lat: latitude, lon: longitude },
    { enabled: !!latitude || !!longitude, staleTime: time }
  );
};
