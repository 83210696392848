import React from 'react';
import styled from 'styled-components';
import { displayTime } from '../../helpers/display-time';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 32px;
  margin-right: 32px;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.neutral.white};
`;

type SubtextProps = {
  align: 'left' | 'right';
};

const Subtext = styled.div<SubtextProps>`
  font-size: ${(props) => props.theme.fontSizes[2]}px;
  text-align: ${(props) => props.align};
  color: ${(props) => props.theme.colors.neutral.normal};
`;

type SunTimesProps = {
  sunrise: Date;
  sunset: Date;
};

export const SunTimes = ({ sunrise, sunset }: SunTimesProps) => {
  return (
    <Container>
      <div>
        <Subtext align="left">Sunrise</Subtext>
        {displayTime(sunrise)}
      </div>
      <div>
        <Subtext align="right">Sunset</Subtext>
        {displayTime(sunset)}
      </div>
    </Container>
  );
};
