import styled from 'styled-components';

export const StyledCurrentTime = styled.span`
  display: block;
  color: ${(props) => props.theme.colors.neutral.white};

  .time {
    font-size: ${(props) => props.theme.fontSizes[6]}px;
    text-align: center;
  }
`;

export const StyledWeatherSummary = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;

  padding: 4px;
  height: 100px;
  width: 260px;
  margin-top: 8px;

  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;

  /* Grid items */
  > * {
    align-self: stretch;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    flex-basis: calc(50%);
    height: calc(50%);
  }

  .value {
    font-size: ${(props) => props.theme.fontSizes[4]}px;
    line-height: 1;
  }

  .label {
    color: rgba(255, 255, 255, 0.85);
    font-weight: bold;
    font-size: 12px;
    line-height: 1.5;
  }

  .weather-icon {
    position: relative;
    top: 4px;
  }
`;
