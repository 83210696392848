import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  text-align: left;
  width: 100%;

  padding: ${({ theme }) => theme.space[5]}px;
`;

export const Header = styled.div`
  align-items: center;
  text-align: center;
  position: relative;
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  justify-content: center;

  > button {
    display: inline-block;
    position: absolute;
    left: 0;
    color: #444;
    background: ${({ theme }) => theme.colors.neutral.lightest};
  }
`;

export const Heading = styled.h2`
  text-align: center;
`;
