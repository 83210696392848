import React, { useEffect } from 'react';
import { useLocalStorage } from '../../../../helpers';
import { isInstalled } from '../../helpers';
import { Container, CloseButton, InfoBanner } from './styles';

declare global {
  interface Window {
    shouldBlockScroll: boolean;
  }
}

export const InstallBanner = () => {
  const [shouldPrompt, setShouldPrompt] = useLocalStorage('shouldPrompt', true);
  const installed = isInstalled();
  const showPrompt = !installed && shouldPrompt;

  useEffect(() => {
    if (!installed && shouldPrompt) {
      window.shouldBlockScroll = false;
      return;
    }

    window.shouldBlockScroll = true;
  }, [installed, shouldPrompt]);

  if (installed) {
    return null;
  }

  return (
    <>
      {showPrompt && (
        <Container>
          <InfoBanner>
            <div className="flex justify-between items-center mb-4">
              <h2 className="font-bold text-lg">Install Suntime</h2>
              <CloseButton onClick={() => setShouldPrompt(false)}>
                Not right now
              </CloseButton>
            </div>
            <p className="mb-4">
              This website works like an app! To install it on your device:
            </p>
            <table className="mb-4">
              <tbody>
                <tr>
                  <td>Iphone</td>
                  <td>
                    Use Safari to {`"`}Add to Homescreen{`"`}
                  </td>
                </tr>
                <tr>
                  <td>Android/Chrome</td>
                  <td>Install from Chrome{`'`}s menu</td>
                </tr>
              </tbody>
            </table>
          </InfoBanner>
        </Container>
      )}
    </>
  );
};
