import React, { useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useDebounce } from '../../../../helpers';
import { getLocationSearch } from '../../helpers/get-location-search';
import { LocationResult } from '../location-result';

type LocationResultsProps = {
  query: string;
};

export const LocationResults = ({ query }: LocationResultsProps) => {
  const [debouncedQuery, setDebouncedQuery] = useDebounce(query, 500, true);

  useEffect(() => {
    setDebouncedQuery(query);
  }, [query, setDebouncedQuery]);

  const isTyping = useMemo(
    () => query !== debouncedQuery,
    [query, debouncedQuery]
  );

  const { isLoading, error, data } = useQuery(
    ['locationSearch', debouncedQuery],
    () => getLocationSearch(debouncedQuery),
    {
      enabled: debouncedQuery?.length > 0
    }
  );

  if (debouncedQuery.length > 0 && (isLoading || isTyping))
    return <>Loading...</>;
  if (error) {
    const err = error as Error;
    return <>An error has occurred: {err.message}</>;
  }
  if (!data) return null;
  if (!data?.length) return <>No Results</>;

  return (
    <div>
      {data.map((location: any) => (
        <LocationResult key={location.id} location={location} />
      ))}
    </div>
  );
};
