import React from 'react';
import { degToRad, getCircleX, getCircleY } from '../../../../helpers';
import { getDegFromPercent } from './get-deg-from-percent';
import { StyledSun } from './styles';

type SunProps = {
  sunProgress: number;
  size: number;
};

export const Sun = ({ sunProgress, size }: SunProps) => {
  // don't render if stuff isn't ready yet
  if (isNaN(sunProgress)) return null;

  const rad = degToRad(getDegFromPercent(223, 327, sunProgress));
  const center = size / 2;
  const small = {
    center,
    radius: size / 14,
    x: center + getCircleX(rad, center),
    y: center + getCircleY(rad, center)
  };

  return (
    <StyledSun width={size} height={size}>
      <circle id="sun" r={small.radius} cx={small.x} cy={small.y} />
    </StyledSun>
  );
};
