// const iOSCanInstall = 'standalone' in window.navigator;
// const iOSIsInstalled = window.navigator.standalone === true;

export const isInstalled = () => {
  const navigator = window.navigator as any;
  // For iOS
  if (navigator.standalone) {
    console.log('installed');
    return true;
  }

  // For Android
  if (window.matchMedia('(display-mode: standalone)').matches) {
    console.log('installed');
    return true;
  }

  // If neither is true, it's not installed
  return false;
};
