const pi = 3.145;

export const circumference = (radius: number) => 2 * pi * radius;

export const arcLength = (radius: number, percentage: number) =>
  circumference(radius) * percentage;

export const degToRad = (deg: number) => deg * (pi / 180);

export function getCircleX(radians: number, radius: number) {
  return Math.cos(radians) * radius;
}

export function getCircleY(radians: number, radius: number) {
  return Math.sin(radians) * radius;
}
