import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px;
`;

export const InfoBanner = styled.div`
  text-align: left;
  padding: ${(props) => props.theme.space[4]}px;
  padding-bottom: ${(props) => props.theme.space[2]}px;
  border-radius: ${(props) => props.theme.radii[2]};
  display: block;
  width: 100%;
  background: rgb(219, 234, 254);
  td {
    padding-bottom: 8px;
  }
  tr td:first-child {
    font-weight: bold;
    padding-right: 16px;
  }
`;

export const CloseButton = styled.button`
  border: 1px solid #777;
  border-radius: 4px;
  cursor: pointer;
  color: rgb(153, 27, 27);
  background: transparent;
  border: 1px solid rgb(153, 27, 27);
  padding: 4px 12px;
`;
