import styled from 'styled-components';

export const Container = styled.div`
  text-align: left;
  margin-bottom: ${({ theme }) => theme.space[4]}px;
`;

export const InfoContainer = styled.div`
  margin: ${({ theme }) => theme.space[5]}px;
  margin-top: ${({ theme }) => theme.space[6]}px;
  h1 {
    margin-bottom: 8px;
  }
  p {
    margin: 12px 0;
  }
  ul {
    list-style: none;
    margin-top: 8px;
    padding-left: 0px;
    li {
      display: flex;
      padding-bottom: 4px;
      span {
        padding-right: 8px;
      }
    }
  }
`;
